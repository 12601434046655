import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Harbor Press Kit`}</h1>
    <p>{`My name is `}<a parentName="p" {...{
        "href": "https://hal.codes"
      }}>{`Hal Lee`}</a>{`. I’m a 28-year-old software engineer at `}<a parentName="p" {...{
        "href": "https://qz.com"
      }}>{`Quartz`}</a>{` and founder of an independent app agency, `}<a parentName="p" {...{
        "href": "https://pixelfoundry.io"
      }}>{`Pixel Foundry`}</a>{`. I’ve been developing apps for iOS for about six years.`}</p>
    <p>{`I’ve built the RSS reader I always wanted: one with a beautiful interface, smart unified timeline, and no reliance on any third-party services for fetching or syncing feeds. I wanted a feed reader that I could browse casually like I do my Twitter timeline, with the ability to aggregate from hundreds of sources without feeling too overwhelming.`}</p>
    <p>{`Harbor keeps its unified timeline manageable with a unique feed rating system. When you subscribe to a feed, you can tell Harbor you’re a “casual reader” or a “completionist” for that feed. Harbor then tries to ensure that you won’t miss a post from that blog you love that only posts once a month, while still allowing you to subscribe to feeds that post dozens of times per day.`}</p>
    <h2>{`Screenshots`}</h2>
    <p><a parentName="p" {...{
        "href": "/HarborScreenshots.zip"
      }}>{`Download all screenshots`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1430880c4fbec53b72d8a50f50708e2b/e93cc/HarborHero.webp 300w", "/static/1430880c4fbec53b72d8a50f50708e2b/b0544/HarborHero.webp 600w", "/static/1430880c4fbec53b72d8a50f50708e2b/68fc1/HarborHero.webp 1200w", "/static/1430880c4fbec53b72d8a50f50708e2b/a2303/HarborHero.webp 1800w", "/static/1430880c4fbec53b72d8a50f50708e2b/4293a/HarborHero.webp 2400w", "/static/1430880c4fbec53b72d8a50f50708e2b/b2d16/HarborHero.webp 3500w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/1430880c4fbec53b72d8a50f50708e2b/eed55/HarborHero.png 300w", "/static/1430880c4fbec53b72d8a50f50708e2b/7491f/HarborHero.png 600w", "/static/1430880c4fbec53b72d8a50f50708e2b/8537d/HarborHero.png 1200w", "/static/1430880c4fbec53b72d8a50f50708e2b/d2cc9/HarborHero.png 1800w", "/static/1430880c4fbec53b72d8a50f50708e2b/00711/HarborHero.png 2400w", "/static/1430880c4fbec53b72d8a50f50708e2b/9697f/HarborHero.png 3500w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/1430880c4fbec53b72d8a50f50708e2b/8537d/HarborHero.png",
            "alt": "Harbor Hero Image",
            "title": "Harbor Hero Image",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <div className="press">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "949px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "202.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a6709cd324ec8b7816ad18da0cd067a7/e93cc/timeline-light.webp 300w", "/static/a6709cd324ec8b7816ad18da0cd067a7/b0544/timeline-light.webp 600w", "/static/a6709cd324ec8b7816ad18da0cd067a7/51160/timeline-light.webp 949w"],
              "sizes": "(max-width: 949px) 100vw, 949px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a6709cd324ec8b7816ad18da0cd067a7/eed55/timeline-light.png 300w", "/static/a6709cd324ec8b7816ad18da0cd067a7/7491f/timeline-light.png 600w", "/static/a6709cd324ec8b7816ad18da0cd067a7/60eb2/timeline-light.png 949w"],
              "sizes": "(max-width: 949px) 100vw, 949px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a6709cd324ec8b7816ad18da0cd067a7/60eb2/timeline-light.png",
              "alt": "iPhone Home Light",
              "title": "iPhone Home Light",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>{` `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "949px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "202.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e3ea8615611815da6204e3130a9b9381/e93cc/timeline-dark.webp 300w", "/static/e3ea8615611815da6204e3130a9b9381/b0544/timeline-dark.webp 600w", "/static/e3ea8615611815da6204e3130a9b9381/51160/timeline-dark.webp 949w"],
              "sizes": "(max-width: 949px) 100vw, 949px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e3ea8615611815da6204e3130a9b9381/eed55/timeline-dark.png 300w", "/static/e3ea8615611815da6204e3130a9b9381/7491f/timeline-dark.png 600w", "/static/e3ea8615611815da6204e3130a9b9381/60eb2/timeline-dark.png 949w"],
              "sizes": "(max-width: 949px) 100vw, 949px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e3ea8615611815da6204e3130a9b9381/60eb2/timeline-dark.png",
              "alt": "iPhone Home Dark",
              "title": "iPhone Home Dark",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "949px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "202.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/72c7199d313e911894624763057765c7/e93cc/post-light.webp 300w", "/static/72c7199d313e911894624763057765c7/b0544/post-light.webp 600w", "/static/72c7199d313e911894624763057765c7/51160/post-light.webp 949w"],
              "sizes": "(max-width: 949px) 100vw, 949px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/72c7199d313e911894624763057765c7/eed55/post-light.png 300w", "/static/72c7199d313e911894624763057765c7/7491f/post-light.png 600w", "/static/72c7199d313e911894624763057765c7/60eb2/post-light.png 949w"],
              "sizes": "(max-width: 949px) 100vw, 949px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/72c7199d313e911894624763057765c7/60eb2/post-light.png",
              "alt": "iPhone Post Light",
              "title": "iPhone Post Light",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>{` `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "949px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "202.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4eb416ca7091d866799296c5fed416f5/e93cc/post-dark.webp 300w", "/static/4eb416ca7091d866799296c5fed416f5/b0544/post-dark.webp 600w", "/static/4eb416ca7091d866799296c5fed416f5/51160/post-dark.webp 949w"],
              "sizes": "(max-width: 949px) 100vw, 949px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4eb416ca7091d866799296c5fed416f5/eed55/post-dark.png 300w", "/static/4eb416ca7091d866799296c5fed416f5/7491f/post-dark.png 600w", "/static/4eb416ca7091d866799296c5fed416f5/60eb2/post-dark.png 949w"],
              "sizes": "(max-width: 949px) 100vw, 949px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4eb416ca7091d866799296c5fed416f5/60eb2/post-dark.png",
              "alt": "iPhone Post Dark",
              "title": "iPhone Post Dark",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "949px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "202.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/96a72336ed0dd1dfa2f8b10f857595ff/e93cc/explore-light.webp 300w", "/static/96a72336ed0dd1dfa2f8b10f857595ff/b0544/explore-light.webp 600w", "/static/96a72336ed0dd1dfa2f8b10f857595ff/51160/explore-light.webp 949w"],
              "sizes": "(max-width: 949px) 100vw, 949px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/96a72336ed0dd1dfa2f8b10f857595ff/eed55/explore-light.png 300w", "/static/96a72336ed0dd1dfa2f8b10f857595ff/7491f/explore-light.png 600w", "/static/96a72336ed0dd1dfa2f8b10f857595ff/60eb2/explore-light.png 949w"],
              "sizes": "(max-width: 949px) 100vw, 949px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/96a72336ed0dd1dfa2f8b10f857595ff/60eb2/explore-light.png",
              "alt": "iPhone Explore Light",
              "title": "iPhone Explore Light",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>{` `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "949px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "202.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/005ff446ab6b28637ece8ec6eb1dce41/e93cc/explore-dark.webp 300w", "/static/005ff446ab6b28637ece8ec6eb1dce41/b0544/explore-dark.webp 600w", "/static/005ff446ab6b28637ece8ec6eb1dce41/51160/explore-dark.webp 949w"],
              "sizes": "(max-width: 949px) 100vw, 949px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/005ff446ab6b28637ece8ec6eb1dce41/eed55/explore-dark.png 300w", "/static/005ff446ab6b28637ece8ec6eb1dce41/7491f/explore-dark.png 600w", "/static/005ff446ab6b28637ece8ec6eb1dce41/60eb2/explore-dark.png 949w"],
              "sizes": "(max-width: 949px) 100vw, 949px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/005ff446ab6b28637ece8ec6eb1dce41/60eb2/explore-dark.png",
              "alt": "iPhone Explore Dark",
              "title": "iPhone Explore Dark",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "138.66666666666669%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b3d8e5bc44f02d5750ffc1fd886d3cba/e93cc/ipad-timeline-light.webp 300w", "/static/b3d8e5bc44f02d5750ffc1fd886d3cba/b0544/ipad-timeline-light.webp 600w", "/static/b3d8e5bc44f02d5750ffc1fd886d3cba/68fc1/ipad-timeline-light.webp 1200w", "/static/b3d8e5bc44f02d5750ffc1fd886d3cba/eb1b9/ipad-timeline-light.webp 1385w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b3d8e5bc44f02d5750ffc1fd886d3cba/eed55/ipad-timeline-light.png 300w", "/static/b3d8e5bc44f02d5750ffc1fd886d3cba/7491f/ipad-timeline-light.png 600w", "/static/b3d8e5bc44f02d5750ffc1fd886d3cba/8537d/ipad-timeline-light.png 1200w", "/static/b3d8e5bc44f02d5750ffc1fd886d3cba/752c2/ipad-timeline-light.png 1385w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b3d8e5bc44f02d5750ffc1fd886d3cba/8537d/ipad-timeline-light.png",
              "alt": "iPad Home",
              "title": "iPad Home",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>{` `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "138.66666666666669%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0530cd2129f5d7581901439ff35d2259/e93cc/ipad-timeline-dark.webp 300w", "/static/0530cd2129f5d7581901439ff35d2259/b0544/ipad-timeline-dark.webp 600w", "/static/0530cd2129f5d7581901439ff35d2259/68fc1/ipad-timeline-dark.webp 1200w", "/static/0530cd2129f5d7581901439ff35d2259/eb1b9/ipad-timeline-dark.webp 1385w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0530cd2129f5d7581901439ff35d2259/eed55/ipad-timeline-dark.png 300w", "/static/0530cd2129f5d7581901439ff35d2259/7491f/ipad-timeline-dark.png 600w", "/static/0530cd2129f5d7581901439ff35d2259/8537d/ipad-timeline-dark.png 1200w", "/static/0530cd2129f5d7581901439ff35d2259/752c2/ipad-timeline-dark.png 1385w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0530cd2129f5d7581901439ff35d2259/8537d/ipad-timeline-dark.png",
              "alt": "iPad Home",
              "title": "iPad Home",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "82%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/a057c0a0f5de5ed0c9311cab2d987dcc/e93cc/mac-timeline-dark.webp 300w", "/static/a057c0a0f5de5ed0c9311cab2d987dcc/b0544/mac-timeline-dark.webp 600w", "/static/a057c0a0f5de5ed0c9311cab2d987dcc/68fc1/mac-timeline-dark.webp 1200w", "/static/a057c0a0f5de5ed0c9311cab2d987dcc/a2303/mac-timeline-dark.webp 1800w", "/static/a057c0a0f5de5ed0c9311cab2d987dcc/f3ff0/mac-timeline-dark.webp 1920w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/a057c0a0f5de5ed0c9311cab2d987dcc/eed55/mac-timeline-dark.png 300w", "/static/a057c0a0f5de5ed0c9311cab2d987dcc/7491f/mac-timeline-dark.png 600w", "/static/a057c0a0f5de5ed0c9311cab2d987dcc/8537d/mac-timeline-dark.png 1200w", "/static/a057c0a0f5de5ed0c9311cab2d987dcc/d2cc9/mac-timeline-dark.png 1800w", "/static/a057c0a0f5de5ed0c9311cab2d987dcc/7d442/mac-timeline-dark.png 1920w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/a057c0a0f5de5ed0c9311cab2d987dcc/8537d/mac-timeline-dark.png",
            "alt": "Mac",
            "title": "Mac",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "/HarborScreenshots.zip"
      }}>{`Download all screenshots`}</a></p>
    <h2>{`Icon`}</h2>
    <div className="press">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "512px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c784e28373326fef3f5fb07aa7301b85/e93cc/HarborIcon.webp 300w", "/static/c784e28373326fef3f5fb07aa7301b85/1bb89/HarborIcon.webp 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c784e28373326fef3f5fb07aa7301b85/eed55/HarborIcon.png 300w", "/static/c784e28373326fef3f5fb07aa7301b85/b7655/HarborIcon.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c784e28373326fef3f5fb07aa7301b85/b7655/HarborIcon.png",
              "alt": "iOS icon",
              "title": "iOS icon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>{` `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ded3eb2e0736067986cb9d828d9c3e05/e93cc/HarborMacIcon.webp 300w", "/static/ded3eb2e0736067986cb9d828d9c3e05/b0544/HarborMacIcon.webp 600w", "/static/ded3eb2e0736067986cb9d828d9c3e05/53334/HarborMacIcon.webp 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ded3eb2e0736067986cb9d828d9c3e05/eed55/HarborMacIcon.png 300w", "/static/ded3eb2e0736067986cb9d828d9c3e05/7491f/HarborMacIcon.png 600w", "/static/ded3eb2e0736067986cb9d828d9c3e05/658fc/HarborMacIcon.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ded3eb2e0736067986cb9d828d9c3e05/658fc/HarborMacIcon.png",
              "alt": "Mac icon",
              "title": "Mac icon",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </div>
    <h2>{`More details`}</h2>
    <h3>{`Pricing`}</h3>
    <p>{`Harbor is free to download and use up to 5 feed subscriptions.
An in-app subscription to Harbor Premium unlocks unlimited feed subscriptions, for $0.99 per month or $9.99 per year.`}</p>
    <h3>{`Features`}</h3>
    <p>{`Here are some of Harbor’s unique features:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A solution for “post frequency disparity”`}</strong>{` (PFD). OK, I made that term up. But PFD, to me, is a common issue with RSS: some publications post dozens of times per day. Others—my woefully neglected `}<a parentName="p" {...{
            "href": "https://hal.codes/blog/"
          }}>{`personal blog`}</a>{`, for one—might only post a handful of times per year. But those rarely-posted blogs might be the ones I’m most interested in reading! In a typical chronological timeline, frequent-posting-feeds quickly overwhelm rarely-posting ones. Harbor’s unified timeline makes smart adjustments to make sure that rare blog post you don’t want to miss stays toward the top of your timeline for longer. Feeds you’ve marked as “completionist” are given priority in the feed and stick around longer; feeds marked as “casual reader” more quickly drop out of the timeline.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Independence from third-party services`}</strong>{`. Many RSS readers depend on a third-party service for syncing and finding feeds. To me, this makes RSS readers less approachable than, say, podcast apps, which rarely require a third-party sign in to function. Like a typical podcast app, Harbor manages your feed subscriptions directly, and can sync your subscriptions via iCloud automatically to all your devices.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Beautiful feed directory`}</strong>{`. Another great feature I’ve cribbed from podcast apps is a categorized, centralized feed directory. People shouldn’t have to know the full URL to an RSS feed’s XML source. Feeds should be searchable by title. Harbor’s still-growing ‘Explore’ directory aims to solve that, and I think it’s already a great place to window shop for new RSS feeds.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`No unread indicators`}</strong>{`. Seeing 100 little unread badges in an RSS reader gives me anxiety. Twitter’s timeline mostly works without unread indicators. I wanted Harbor to communicate that it’s OK to miss some posts.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Syntax highlighting`}</strong>{`. There is a rich community of programmers with personal blogs who share source code in their posts. Harbor adds syntax highlighting to source code, making it much easier to read in the in-app reader.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      